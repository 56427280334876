import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css'; // Make sure you have the CSS for the split UI and typewriter effect

function App() {
  const [userInput, setUserInput] = useState('');
  const [data, setData] = useState({
    model: "turbogpt",
    max_tokens: 400,
    messages: [
      {
        role: "system",
        content: `"I want you to be my prompt generator. Your goal is to help me create the best possible ChatGPT prompt for my needs. The prompt should explore the limits of the Large Language Model behind ChatGPT.

        It is important that you follow the steps below:
        
        1. in my [input] I explain what the prompt should be about. But we need to improve it by constantly repeating it, going through the next steps.
        2. Based on my [input], you create three sections: a) Revised prompt (You formulate your revised prompt. It should be clear, concise and easy for ChatGPT to understand), b) Suggestions (you make suggestions on what details you should include in the prompt to improve it) and c) Questions (you ask relevant questions about what additional information you need to improve the prompt).
        3. the prompt you provide should take the form of a request from me to ChatGPT.
        4. we will keep repeating this iterative process, with me providing you with additional information and you updating the prompt in the "Revised prompt" section until it is complete.
        [input]`
      }
    ]
  });
  const [displayedText, setDisplayedText] = useState('');
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const lastMessageContent = data.messages.findLast(msg => msg.role === "assistant")?.content || '';
    if (isTyping && displayedText !== lastMessageContent) {
      setTimeout(() => {
        setDisplayedText(lastMessageContent.substring(0, displayedText.length + 1));
      }, 30); // Speed of the typewriter effect
    } else if (displayedText === lastMessageContent) {
      setIsTyping(false);
    }
  }, [displayedText, isTyping, data]);

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSend = () => {
    console.log("data", data)
    // Append user input as a new message
    const newUserMessage = {
      role: "user",
      content: userInput
    };

    setData(prevData => ({
      ...prevData,
      messages: [...prevData.messages, newUserMessage]
    }));
    
    refinePrompt([...data.messages, newUserMessage]); // Make the API call with the updated messages
    setUserInput(''); // Reset input field
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      handleSend();
    }
  };

  const refinePrompt = async (updatedMessages) => {
    const updatedData = {
      ...data,
      messages: updatedMessages
    };

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://vodex-openai.openai.azure.com/openai/deployments/turbogpt/chat/completions?api-version=2023-03-15-preview',
      headers: { 
        'Content-Type': 'application/json', 
        'api-key': 'fc5089a2b8dc49289e8f00f061243c33'
      },
      data: JSON.stringify(updatedData)
    };

    try {
      const response = await axios(config);
      const assistantMessages = response.data.choices.map(choice => choice.message);

      setData(prevData => ({
        ...prevData,
        messages: [...prevData.messages, ...assistantMessages]
      }));

      if (assistantMessages.length > 0) {
        setIsTyping(true);
        setDisplayedText('');
      }
    } catch (error) {
      console.error('Error fetching script:', error);
    }
  };

  // Render the chat history with role "user"
  const renderChatHistory = () => {
    return data.messages
      .filter(msg => msg.role === "user")
      .map((msg, index) => (
        <p key={index}>{msg.content}</p>
      ));
  };

  return (
    <div className="App">
      <div className="chat-interface">
        <div className="chat-history">
          {renderChatHistory()}
        </div>
        <div className="input-container">
          <input type="text" value={userInput} onChange={handleInputChange} onKeyPress={handleKeyPress} />
          <button onClick={handleSend}>Send</button>
        </div>
      </div>
      <div className="refined-prompt">
        <p>{isTyping ? displayedText : (data.messages.findLast(msg => msg.role === "assistant")?.content || '')}</p>
        <div className="powered-by">
          <a href="https://www.vodex.ai" target="_blank" rel="noopener noreferrer">Powered by <span className='orange'>Vodex.ai</span></a>
        </div>
      </div>
    </div>
  );
}

export default App;
